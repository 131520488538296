/* global  Chart, calc, isObject, mergeDeep, utils */

/**
 * Prepare a dataset such that chart.js internals like it
 * @param {Object} crop         Crop object
 * @param {number[]} arr        Array of values with which to make a dataset
 * @param {int} decimalPlaces   Number of decimals
 * @returns {Object[]}          Array of objects containing the x and y values for the dataset
 */
window.cjs_data = function(crop, arr, decimalPlaces = 2) {
  if (arr == null) {
    return {x: 0, y: 0};
  }
  const start_date = new Date(crop.start);
  const offset = start_date.getTime() + start_date.getTimezoneOffset() * 60 * 1000;
  return arr.map(function(v, i) {
    // offset by 1 week to match week 1 with index 0, offset by another 2/7 week to match monday with graph tick
    return {
      // extra hour offset due to summer/winter time
      x: offset + i * WEEK + 60 * 60 * 1000,
      y: (v !== null && !isNaN(parseFloat(v))) ? v.toFixedNumber(decimalPlaces) : null
    };
  });
};

/**
 * day offset in milliseconds
 * @returns {number}    day offset in milliseconds
 */
window.graph_x_offset = function() {
  const offset = 1 * 24 * 60 * 60 * 1000;
  return offset;
};

/**
 * round function for numbers
 * @param {int} digits  how many decimals to round to
 * @returns {number}    rounded number
 */
Number.prototype.toFixedNumber = function(digits) {
  const pow = 10 ** digits;
  return Math.round(this * pow) / pow;
};

/**
 * Convert times to milliseconds since epoch
 * @param {Object} crop   Crop object
 * @param {int} i         Index of value in array
 * @returns {number}      Start of week in millisecondsa since epoch
 */
window.week_start = function(crop, i) {
  const offset = getMonday(crop.start).getTime();
  return offset + i * WEEK;
};

/**
 * Get date for corresponding monday of give date
 * @param {string} d    Given date in YYYY-MM-DD
 * @returns {Date}      Monday corresponding to given date
 */
function getMonday(d) {
  d = new Date(d);
  const day = (d.getDay() + 6) % 7; // make sunday last day of previous week
  const diff = d.getDate() - day + 1; // go to the 1st day of the week (0th is sunday)
  const res = new Date(d.setDate(diff));
  return res;
}

const WEEK = 7 * 24 * 60 * 60 * 1000;

/**
 * Get week number of week for given date
 * @param {Date} date     Given date
 * @returns {int}         Week number of given date
 */
window.iso_week_no = function(date) {
  // make date object
  const date1 = new Date(date.valueOf());
  // set index 0 to monday instead of sunday
  const dayn = (date.getDay() + 6) % 7;
  // get thursday of given week
  date1.setDate(date1.getDate() - dayn + 3);
  const firstThursday = date1.valueOf();
  // get new date for first thursday of year
  const date2 = new Date(date1.valueOf());
  date2.setMonth(0, 1);
  if (date2.getDay() !== 4) {
    date2.setMonth(0, 1 + ((4 - date2.getDay()) + 7) % 7);
  }
  // get week number based on difference between thursday of this week and first thursday of year
  return 1 + Math.ceil((firstThursday - date2) / WEEK);
};

/**
 * Get week number of week for given date
 * @param {Object} crop   Crop object
 * @param {int} i         Index of week
 * @returns {int}         Week number of index
 */
window.abs_iso_week_num = function(crop, i) {
  const start = getMonday(crop.start).valueOf();
  const offset = i * WEEK - window.graph_x_offset();
  return window.iso_week_no(new Date(start + offset));
};

/**
 * Store the chart object on window (with the id name, so we won't get collisions)
 * @param {int} id          css id name
 * @param {Object} config   configuration hash
 */
function ck(id, config) {
  window[id] = new Chart(id, config);
}

/**
 * Add chart object if applicable
 * @param {int} id          css id name
 * @param {Object} config   configuration hash
 */
window.createChart = function(id, config) {
  if ('Chart' in window) {
    ck(id, config);
  } else {
    window.addEventListener('chartjs:load', () => ck(id, config), true);
  }
};

/**
 * Calculate sum of all values in array with lower (and equal) index than the one given
 * @param {number[]} arr      Array from which to calculate the sum
 * @param {int} index         Index up to which the array values are summed
 * @param {bool} fill_nulls   Whether or not to fill empty values in array
 * @returns {number}          Total sum
 */
window.sumValuesAbove = function(arr, index, fill_nulls = true) {
  if (fill_nulls) {
    return utils.fill_nulls(arr.slice(0, index + 1)).reduce((a, b) => a + b, 0);
  } else {
    return arr.slice(0, index + 1).reduce((a, b) => a + b, 0);
  }
};

/**
 * Simple is object check.
 * @param item
 * @return {boolean}
 */
window.isObject = function(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
};

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
window.mergeDeep = function(target, source) {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {[key]: {}});
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {[key]: source[key]});
      }
    }
  }
  return target;
};

/**
 * Create object containing values from both passed objects
 * without modifying them
 */
window.combine = function(obj1, obj2) {
  let obj = JSON.parse(JSON.stringify(obj1)); // eslint-disable-line prefer-const
  return mergeDeep(obj, obj2);
};

// https://gist.github.com/joates/6584908
/**
 * Get linspace array
 * @param {number} a      lower bound
 * @param {number} b      upper bound
 * @param {int} n         number of elements
 * @returns {number[]}    linspace array
 */
function linspace(a, b, n) {
  if (typeof n === 'undefined') n = Math.max(Math.round(b - a) + 1, 1);
  if (n < 2) {
    return n === 1 ? [a] : [];
  }
  let i; const ret = Array(n);
  n--;
  for (i = n; i >= 0; i--) {
    ret[i] = (i * b + (n - i) * a) / n;
  }
  return ret;
}

/**
 * Plot of daily growth
 * @param {Object} el                           graph element
 * @param {number} maximum_light_use            Maximum hours of usable light
 * @param {number} efficiency_factor            Percentage based efficiency factor
 * @param {number[]} optimal_available_light    Array of setpoints for optimal light corresponding to temperature
 * @param {number[]} optimal_temp               Array of setpoints for optimal temperature corresponding to light
 * @param {bool} per_joule                      Whether or not to return relative efficiency values
 * @param {Object} plant_profile                Plant profile object
 * @param {string} title                        Plot title
 * @param {string} x_title                      x-axis label
 * @param {string} y_title                      y-axis label
 * @param {string} title_short                  Short version of plot title
 * @param {string} x_title_short                Short version of x-axis label
 * @param {string} y_title_short                Short version of y-axis label
 */
function daily_growth_plot(el,
    maximum_light_use,
    light_requirement,
    optimal_available_light,
    optimal_temp,
    per_joule,
    plant_profile,
    title,
    x_title,
    y_title,
    title_short,
    x_title_short,
    y_title_short) {
  window.Plotly.react(el,
      daily_growth_plot_data(
          maximum_light_use,
          light_requirement,
          optimal_available_light,
          optimal_temp,
          per_joule,
          plant_profile,
          title_short,
          x_title_short,
          y_title_short
      ),
      {paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        xaxis: {title: {text: x_title}, range: [0, 2000], hoverformat: '.0f'},
        yaxis: {title: {text: y_title}, range: [10, 30], hoverformat: '.1f'},
        title: title,
        legend: {yanchor: 'bottom'},
        modebar: {orientation: 'v'}});
}


/**
 * Calculate daily growth value
 * @param {number} E                    Incoming light value
 * @param {number} T                    Temperature value
 * @param {number} max_usable_light     Maximum hours of usable light
 * @param {number} light_requirement    light requirement value
 * @param {Object} plant_profile        Plant profile object
 * @param {boolean} use_temp_influence  Whether or not to use the temperature influence factor
 * @param {boolean} per_joule           Whether or not to return relative efficiency values
 * @returns {number}                    Expected daily growth
 */
function calc_daily_growth(
    E,
    T,
    max_usable_light,
    light_requirement,
    plant_profile,
    use_temp_influence = true,
    per_joule = true) {
  return (
    (use_temp_influence ? calc.temperature_factor(T, E, plant_profile) : 1) *
    (per_joule ? 1 : Math.min(E, max_usable_light || 10000) / light_requirement)
  );
}


/**
 * Calculate temperature based on incoming light
 * @param {number} E                    Incoming light value
 * @param {Object} plant_profile        Plant profile object
 * @returns {number}                    Temperature value
 */
function calc_optimal_temp(E, plant_profile) {
  const optimal_available_light = plant_profile.optimal_available_light;
  const optimal_temp = plant_profile.optimal_temp;
  const light_joules = linspace(0, 2000, 81);
  const interpolator = calc.temp_setpoint_interpolator(optimal_available_light, optimal_temp);
  const z = interpolator(light_joules);
  const idx_optimal_temp = light_joules.reduce((iMax, x, i, arr) =>
    Math.abs(x - E) < Math.abs(arr[iMax] - E) ? i : iMax, 0
  );
  return z[idx_optimal_temp];
}

/**
 * Create data for a contour plot of growth per day, with light and temperature.
 * @param {number} maximum_light_use            Maximum hours of usable light
 * @param {number} efficiency_factor            Percentage based efficiency factor
 * @param {number[]} optimal_available_light    Array of setpoints for optimal light corresponding to temperature
 * @param {number[]} optimal_temp               Array of setpoints for optimal temperature corresponding to light
 * @param {bool} per_joule                      Whether or not to return relative efficiency values
 * @param {Object} plant_profile                Plant profile object
 * @param {string} title_short                  Short version of plot title
 * @param {string} x_title_short                Short version of x-axis label
 * @param {string} y_title_short                Short version of y-axis label
 * @returns {Object[]}                          Necessary data for daily growth plot
 */
function daily_growth_plot_data(max_usable_light,
    light_requirement,
    optimal_available_light,
    optimal_temp,
    per_joule,
    plant_profile,
    title_short,
    x_title_short,
    y_title_short) {
  const light_joules = linspace(0, 2000, 81);
  const average_temperature = linspace(10, 30, 21);

  const use_temp_influence = optimal_temp.length > 0;
  const interpolator = calc.temp_setpoint_interpolator(optimal_available_light, optimal_temp);

  const x = light_joules;
  const y = average_temperature;
  const z = average_temperature.map((T) => light_joules.map((E) =>
    calc_daily_growth(E, T, max_usable_light, light_requirement, plant_profile, use_temp_influence, per_joule) *
    (per_joule ? 100.0 : 1.0)
  ));


  const text = y.map((yj, j) => x.map((xi, i) =>
    [
      [title_short, z[j][i].toFixed(2)],
      [x_title_short, xi],
      [y_title_short, yj]
    ].map(([title_var, value]) =>
      cursor_label_string(title_var, value)).join('<br>')));

  return [{
    x: x,
    y: y,
    z: z,
    text: text,
    hoverinfo: 'text',
    type: 'heatmap',
    name: per_joule ? '%' : 'gr/day/m²',
    showlegend: true,
    colorscale: 'YlGnBu',
    zsmooth: 'best',
    // hovertemplate: '%{z:.2f}',
    // hovertemplate: 'Eff: %{z:.2f}<br>Light:%{y:.2f}',
    zmin: 0,
    zmax: per_joule ? 100.0 : Math.min(max_usable_light, 2000) / light_requirement
  },
  {
    x: optimal_available_light,
    y: optimal_temp,
    mode: 'markers',
    name: 'Setpoint',
    hoverinfo: 'skip',
    marker: {
      size: 10
    }
  },
  {
    x: light_joules,
    y: interpolator(light_joules),
    line: {
      dash: 'dash',
      width: 1.5
    },
    hoverinfo: 'skip',
    name: 'Interpolation'
  }
  ];
}

/**
 * Create data for a lineplot of the influence of CO2 on growth speed
 * @param {Object} el                     graph element
 * @param {number} co2_offset             co2 offset
 * @param {number} co2_slope              co2 slope
 * @param {string} title                  Plot title
 * @param {string} x_title                x-axis label
 * @param {string} y_title                y-axis label
 * @param {string} x_title_short          Short version of x-axis label
 * @param {string} y_title_short          Short version of y-axis label
 */
function co2_effect_plot(
    el,
    co2_offset,
    co2_slope,
    title,
    x_title,
    y_title,
    x_title_short,
    y_title_short) {
  const co2 = linspace(200, 1000, 401);

  const x = co2;
  const y = co2.map((C) => calc.co2_factor(C, co2_offset, co2_slope) * 100);
  const text = x.map((xi, i) =>
    [
      [y_title_short, y[i].toFixed(2)],
      [x_title_short, xi]
    ].map(([title_var, value]) =>
      cursor_label_string(title_var, value)).join('<br>'));

  window.Plotly.newPlot(el, [
    {
      hoverinfo: 'text',
      x: x,
      y: y,
      text: text
    }
  ], {
    yaxis: {title: {text: y_title}, range: [0, 150]},
    xaxis: {title: {text: x_title}},
    title: title,
    paper_bgcolor: 'rgba(0,0,0,0)',
    plot_bgcolor: 'rgba(0,0,0,0)',
    modebar: {orientation: 'v'}
  });
}

/**
 * Plot the temperature correction coefficient for each of the setpoints
 * of available light.
 * @param {Object} el                           graph element
 * @param {number[]} optimal_available_light    Array of setpoints for optimal light corresponding to temperature
 * @param {number[]} optimal_temp               Array of setpoints for optimal temperature corresponding to light
 * @param {Object} plant_profile                Plant profile object
 * @param {string} title                        Plot title
 * @param {string} x_title                      x-axis label
 * @param {string} y_title                      y-axis label
 * @param {string} x_title_short                short version of x-axis label
 * @param {string} y_title_short                short version y-axis label
 */
function optimal_available_light_plot(
    el,
    optimal_available_light,
    optimal_temp,
    plant_profile,
    title,
    x_title,
    y_title,
    x_title_short,
    y_title_short
) {
  const average_temperature = linspace(10, 30, 201);

  window.Plotly.newPlot(el,
      optimal_available_light.map((E, i) => {
        const x = average_temperature;
        // Efficiency factor in percerntage
        const y = average_temperature.map((T) => calc.temperature_factor_raw(T, optimal_temp[i], plant_profile) * 100);
        const text = x.map((xi, i) =>
          [
            [y_title_short, y[i].toFixed(2)],
            [x_title_short, xi]
          ].map(([title_var, value]) =>
            cursor_label_string(title_var, value)).join('<br>'));

        return {
          hoverinfo: 'text',
          text: text,
          x: x,
          y: y,
          name: E,
          showlegend: E == true
        };
      }),
      {paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        xaxis: {title: {text: x_title}, hoverformat: '.1f'},
        yaxis: {title: {text: y_title}, range: [0, 125], hoverformat: '.2f'},
        title: title,
        modebar: {orientation: 'v'}
      });
}

/**
 * Plot the growing_period vs temperature
 * @param {Object} el                           graph element
 * @param {Object} plant_profile                Plant profile object
 * @param {string} title                        Plot title
 * @param {string} x_title                      x-axis label
 * @param {string} y_title                      y-axis label
 * @param {string} x_title_short                short version of x-axis label
 * @param {string} y_title_short                short version y-axis label
 */
function gdd_plot(
    el,
    plant_profile,
    title,
    x_title,
    y_title,
    x_title_short,
    y_title_short
) {
  const average_temperature = linspace(10, 30, 201);
  const gdd = plant_profile.degree_days ||
      calc.degree_days_from_weight(plant_profile.expected_weight, plant_profile.species);
  const tb = calc.GDD_BASE_TEMP;
  const growing_period = average_temperature.map((v, i) => {
    return gdd / (v - tb);
  });
  const x = average_temperature;
  const y = growing_period;
  const text = x.map((xi, i) =>
    [
      [y_title_short, y[i].toFixed(1)],
      [x_title_short, xi]
    ].map(([title_var, value]) =>
      cursor_label_string(title_var, value)).join('<br>'));

  window.Plotly.newPlot(el,
      [
        {
          hoverinfo: 'text',
          text: text,
          x: average_temperature,
          y: growing_period
        }
      ],
      {paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        xaxis: {title: {text: x_title}, hoverformat: '.1f'},
        yaxis: {title: {text: y_title}, range: [0, 125], hoverformat: '.1f'},
        title: title,
        modebar: {orientation: 'v'}
      });
}

/**
 * Animation for light plan coating values
 * @param {int} id                  graph css id
 * @param {int} plan_idx            index for plan
 * @param {int} realized_idx        index for realized
 * @param {int} last_realized_idx   index for last realized
 */
function light_plan_animation(
    id,
    plan_idx,
    realized_idx = null,
    last_realized_idx = null
) {
  id.ctx.fillStyle = 'black';
  id.ctx.textAlign = 'center';
  id.ctx.textBaseline = 'center';
  // did not get ctx.fontSize or anything with font.size to work so ended up with this
  id.ctx.font = id.ctx.font.replace('12px', '11px');
  const min_len_for_print = 2;
  const x_vals = utils.fill_nulls(id.data.datasets[plan_idx].data.map((x) => x['x']));
  let y_vals = [];
  if (realized_idx === null) {
    y_vals = utils.fill_nulls(id.data.datasets[plan_idx].data.map((x) => x['y']));
  } else {
    const y_vals_real = utils.fill_nulls(id.data.datasets[realized_idx].data.map(
        (x) => x['y']
    )).slice(0, last_realized_idx);
    const y_vals_plan = utils.fill_nulls(id.data.datasets[plan_idx].data.map((x) => x['y'])).slice(last_realized_idx);
    y_vals = y_vals_real.concat(y_vals_plan);
  }
  const y_set = [];
  let last_y_set = -999;
  for (let i = 0; i < y_vals.length; i++) {
    if (y_vals[i] != last_y_set) {
      last_y_set = y_vals[i];
      y_set.push(i);
    }
  }
  for (let i = 0; i < y_set.length; i++) {
    const idx1 = y_set[i];
    let idx2 = y_vals.length - 1;
    if (i != y_set.length - 1) {
      idx2 = y_set[i + 1] - 1;
    }
    if (idx2 - idx1 + 1 >= min_len_for_print) {
      const x1 = id.scales.x.getPixelForValue(x_vals[idx1]);
      const x2 = id.scales.x.getPixelForValue(x_vals[idx2]);
      const x_pos = Math.round((x1 + x2) / 2);
      const y_pos = id.scales.y1.getPixelForValue(-5e3);
      const y_val = y_vals[idx1];
      if (y_val !== null && y_val > 0) {
        id.ctx.fillText(y_val + '%', x_pos, y_pos);
      }
    }
  }
}

/**
 * set bar color based on coating value
 * @param {Object} el             graph element
 * @param {number} barpercentage  coating percentage value
 */
function handle_coating(el, barpercentage) {
  el.base = -1e4;
  // decrease bar percentage slightly to prevent overlapping bars
  el.barPercentage = barpercentage - 1e-15;
  el.backgroundColor = el.data.map((x) => 'rgba(0,0,0,' + (x['y'] / 200) + ')');
  el.data[0]['y'] < 0.01 ? el.backgroundColor[0] = 0.1 : '';
  el.data[0]['y'] < 0.01 ? el.data[0]['y'] = null : '';
}

/**
 * Takes in a title label string that includes unit, splits it into an array of two elements
 * @param {string} title      Title label string
 * @returns {string[]}        Array of two elements: title, unit
 */

function name_unit(title) {
  const regexpName = /.+?(?= \[)/;
  const regexpUnit = /(?<=\[).+?(?=\])/;
  return [title.match(regexpName), title.match(regexpUnit)];
}

/**
 * Combines title label and physical property value
 * e.g: ('CO2 [PPM]', 200 ) => 'CO2: 200 PPM'
 * @param {string} title_short      Physical property name and unit
 * @param {number} value            Physical property value
 * @returns {string}                Physical property name, value and unit
 */

function cursor_label_string(title_short, value) {
  return name_unit(title_short)[0] + ': ' + value + ' ' + name_unit(title_short)[1];
}

const Graphs = {
  calc_daily_growth: calc_daily_growth,
  calc_optimal_temp: calc_optimal_temp,
  co2_effect_plot: co2_effect_plot,
  daily_growth_plot: daily_growth_plot,
  handle_coating: handle_coating,
  light_plan_animation: light_plan_animation,
  optimal_available_light_plot: optimal_available_light_plot,
  gdd_plot: gdd_plot,
  getMonday: getMonday,
  WEEK: WEEK
};

window.graphs = Graphs;
module.exports = Graphs;
